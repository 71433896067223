import React from "react";
import _ from "lodash";
import Header from "../components/header/Header";
import GridLayout from "react-grid-layout";
import Toolbox from "./Toolbox";
import { Redirect } from "react-router-dom";

import {
  Background,
  Bar,
  DateAndTime,
  FluidityLine,
  FluidityRectangle_Port,
  FoodGroup_Port,
  HorizontalLine,
  ImageComponent,
  Logo,
  MultiFoodGroup_Port,
  Promotion,
  PromoVertical,
  Saint,
  TitleBar,
  TitleText,
  VerticalLine,
  Weather,
} from "../components/toolboxComponents/";

import Item from "../components/toolboxComponents/models/Item";
import BackgroundModel from "../components/toolboxComponents/models/BackgroundModel";
import BarModel from "../components/toolboxComponents/models/BarModel";
import PromotionModel from "../components/toolboxComponents/models/PromotionModel";
import LogoModel from "../components/toolboxComponents/models/LogoModel";
import ImageModel from "../components/toolboxComponents/models/ImageModel";
import TitleBarModel from "../components/toolboxComponents/models/TitleBarModel";
import MidLineHorizontalModel from "../components/toolboxComponents/models/MidLineHorizontalModel";
import MidLineVerticalModel from "../components/toolboxComponents/models/MidLineVerticalModel";
import DateTimeModel from "../components/toolboxComponents/models/DateTimeModel";
import SaintModel from "../components/toolboxComponents/models/SaintModel";
import { saveDisplay } from "../api/Api";
import WeatherModel from "../components/toolboxComponents/models/WeatherModel";
import FluidityLineModel from "../components/toolboxComponents/models/FluidityLineModel";
import { saveToLS } from "../utils/Utils";
import { getFromLS } from "../utils/Utils";
import FoodGroupModel from "../components/toolboxComponents/models/FoodGroupModel";
import FluidityRectangleModel from "../components/toolboxComponents/models/FluidityRectangleModel";
import MultiFoodGroupModel from "../components/toolboxComponents/models/MultiFoodGroupModel";
import TitleTextModel from "../components/toolboxComponents/models/TitleTextModel";
import PromoVerticalModel from "../components/toolboxComponents/models/PromoVerticalModel";


const defaultToolboxItems = [
  new BackgroundModel("BAC", 1),
  new BarModel("BAR", 2),
  new PromotionModel("PRO", 3),
  new PromoVerticalModel("PRV", 4),
  new LogoModel("LOG", 5),
  new ImageModel("IMG", 6),
  new TitleBarModel("TIT", 7),
  new MidLineHorizontalModel("MLH", 8),
  new MidLineVerticalModel("MLV", 9),
  new DateTimeModel("DAT", 10),
  new SaintModel("SAN", 11),
  new WeatherModel("WEA", 12),
  new FluidityLineModel("FLL", 13),
  new FluidityRectangleModel("FLR", 14),
  new FoodGroupModel("FGP", 15),
  new MultiFoodGroupModel("MFG", 16),
  new TitleTextModel("TTX", 17),
];

const originalLayout = getFromLS("layout") || [];
const originalItems = getFromLS("items") || [];

class EditorLandscape extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      layout: JSON.parse(JSON.stringify(originalLayout)),
      items: JSON.parse(JSON.stringify(originalItems)),
      toolboxItems: defaultToolboxItems,
      newBackgroundCounter: 0,
      newBarCounter: 0,
      newPromotionCounter: 0,
      newLogoCounter: 0,
      newImageCounter: 0,
      newTitleBarCounter: 0,
      newMidLineHorizontalCounter: 0,
      newMidLineVerticalCounter: 0,
      newDateAndTimeCounter: 0,
      newSaintCounter: 0,
      newWeatherCounter: 0,
      newFluidityRectangleCounter: 0,
      newFluidityLineCounter: 0,
      newFoodGroupLandCounter: 0,
      newTitleTextCounter: 0,
      newMultiFoodGroupCounter: 0,
      newPromoVerticalCounter: 0,
      redirectForbidden: false,
    };
    this.onLayoutChange = this.onLayoutChange.bind(this);

    global.localStorage.setItem("orientation", "portrait");
  }

  componentDidMount() {
    if (document.cookie.indexOf("imokb=") == -1) {
      this.setState({ redirectForbidden: true });
    }

    if (this.state.items.length > 0) {
      this.state.items.forEach((item) => {
        console.log(item.i.substring(3));
        switch (item.i.substring(0, 3)) {
          case "BAC":
            this.setState({
              newBackgroundCounter: parseInt(item.i.substring(3)) + 1,
            });
            break;
          case "BAR":
            this.setState({ newBarCounter: parseInt(item.i.substring(3)) + 1 });
            break;
          case "PRO":
            this.setState({
              newPromotionCounter: parseInt(item.i.substring(3)) + 1,
            });
            break;
          case "PRV":
            this.setState({
              newPromoVerticalCounter: parseInt(item.i.substring(3)) + 1,
            });
            break;
          case "LOG":
            this.setState({
              newLogoCounter: parseInt(item.i.substring(3)) + 1,
            });
            break;
          case "IMG":
            this.setState({
              newImageCounter: parseInt(item.i.substring(3)) + 1,
            });
            break;
          case "TIT":
            this.setState({
              newTitleBarCounter: parseInt(item.i.substring(3)) + 1,
            });
            break;
          case "MLH":
            this.setState({
              newMidLineHorizontalCounter: parseInt(item.i.substring(3)) + 1,
            });
            break;
          case "MLV":
            this.setState({
              newMidLineVerticalCounter: parseInt(item.i.substring(3)) + 1,
            });
            break;
          case "DAT":
            this.setState({
              newDateAndTimeCounter: parseInt(item.i.substring(3)) + 1,
            });
            break;
          case "SAN":
            this.setState({
              newSaintCounter: parseInt(item.i.substring(3)) + 1,
            });
            break;
          case "WEA":
            this.setState({
              newWeatherCounter: parseInt(item.i.substring(3)) + 1,
            });
            break;
          case "FLL":
            this.setState({
              newFluidityLineCounter: parseInt(item.i.substring(3)) + 1,
            });
            break;
            case "FLR":
              this.setState({
                newFluidityRectangleCounter: parseInt(item.i.substring(3)) + 1,
              });
              break;
          case "FGP":
            this.setState({
              newFoodGroupLandCounter: parseInt(item.i.substring(3)) + 1,
            });
            break;
          case "TTX":
            this.setState({
              newTitleTextCounter: parseInt(item.i.substring(3)) + 1,
            });
            break;
          case "MFG":
            this.setState({
              newMultiFoodGroupCounter: parseInt(item.i.substring(3)) + 1,
            });
        }
      });
    }
  }

  onSelectTemplate = (templateDisplay) => {
    console.log(templateDisplay[2]);
    saveToLS("layout", templateDisplay[2].layout);
    saveToLS("items", templateDisplay[1].items);

    window.location.reload();
  };

  //Updates and saves the layout each time an element is dragged or resized
  onLayoutChange(layout) {
    console.log("layout updated");
    this.setState({ layout: layout });
  }

  onLayerChange = (itemID, zIndex) => {
    let index = this.state.items.findIndex((x) => x.i === itemID);
    let newItems = this.state.items;
    newItems[index].zIndex = zIndex;
    this.setState({ items: newItems });
  };

  onStyleChange = (item) => {
    let index = this.state.items.findIndex((x) => x.i === item.i);
    let newItems = this.state.items;
    newItems[index] = item;
    this.setState({ items: newItems });
  };

  _onWidthChange = (wid, margin, cols) => {
    console.log("ffffffff");
    var height = (wid * 1) / cols;
    this.setState({
      rowHeight: height,
    });
  };

  //Add an item to the layout when an item in the toolbox is clicked
  onTakeItem = (item) => {
    let itemToAdd = {};
    switch (item.i) {
      case "BAC":
        this.setState({
          newBackgroundCounter: this.state.newBackgroundCounter + 1,
        });
        itemToAdd = new BackgroundModel(
          "BAC" + this.state.newBackgroundCounter,
          (this.state.items.length * 2) % (this.state.cols || 36),
          192,
          108,
          20,
          45
        );
        break;
      case "BAR":
        this.setState({ newBarCounter: this.state.newBarCounter + 1 });
        itemToAdd = new BarModel(
          "BAR" + this.state.newBarCounter,
          (this.state.items.length * 2) % (this.state.cols || 36),
          29,
          108,
          10,
          108
        );
        break;
      case "PRO":
        this.setState({
          newPromotionCounter: this.state.newPromotionCounter + 1,
        });
        itemToAdd = new PromotionModel(
          "PRO" + this.state.newPromotionCounter,
          (this.state.items.length * 2) % (this.state.cols || 36),
          61,
          108,
          15,
          27
        );
        break;
      case "PRV":
        this.setState({
          newPromoVerticalCounter: this.state.newPromoVerticalCounter + 1,
        });
        itemToAdd = new PromoVerticalModel(
          "PRV" + this.state.newPromoVerticalCounter,
          (this.state.items.length * 2) % (this.state.cols || 36),
          108,
          192,
          27,
          15
        );
        break;
      case "LOG":
        this.setState({
          newLogoCounter: this.state.newLogoCounter + 1,
        });

        itemToAdd = new LogoModel(
          "LOG" + this.state.newLogoCounter,
          (this.state.items.length * 2) % (this.state.cols || 36),
          16,
          16,
          12,
          12
        );
        break;
      case "IMG":
        this.setState({
          newImageCounter: this.state.newImageCounter + 1,
        });
        itemToAdd = new ImageModel(
          "IMG" + this.state.newImageCounter,
          (this.state.items.length * 2) % (this.state.cols || 36),
          192,
          108,
          9,
          5
        );
        break;
      case "TIT":
        this.setState({
          newTitleBarCounter: this.state.newTitleBarCounter + 1,
        });
        itemToAdd = new TitleBarModel(
          "TIT" + this.state.newTitleBarCounter,
          (this.state.items.length * 2) % (this.state.cols || 36),
          10,
          64,
          8,
          22
        );
        break;
      case "MLH":
        this.setState({
          newMidLineHorizontalCounter:
            this.state.newMidLineHorizontalCounter + 1,
        });
        itemToAdd = new MidLineHorizontalModel(
          "MLH" + this.state.newMidLineHorizontalCounter,
          (this.state.items.length * 2) % (this.state.cols || 36),
          1,
          108,
          1,
          45
        );
        break;
      case "MLV":
        this.setState({
          newMidLineVerticalCounter: this.state.newMidLineVerticalCounter + 1,
        });
        itemToAdd = new MidLineVerticalModel(
          "MLV" + this.state.newMidLineVerticalCounter,
          (this.state.items.length * 2) % (this.state.cols || 36),
          192,
          1,
          45,
          1
        );
        break;
      case "DAT":
        this.setState({
          newDateAndTimeCounter: this.state.newDateAndTimeCounter + 1,
        });
        itemToAdd = new DateTimeModel(
          "DAT" + this.state.newDateAndTimeCounter,
          (this.state.items.length * 2) % (this.state.cols || 36),
          12,
          16,
          11,
          13
        );
        break;
      case "SAN":
        this.setState({
          newSaintCounter: this.state.newSaintCounter + 1,
        });
        itemToAdd = new SaintModel(
          "SAN" + this.state.newSaintCounter,
          (this.state.items.length * 2) % (this.state.cols || 36),
          6,
          32,
          5,
          26
        );
        break;
      case "WEA":
        this.setState({
          newWeatherCounter: this.state.newWeatherCounter + 1,
        });
        itemToAdd = new WeatherModel(
          "WEA" + this.state.newWeatherCounter,
          (this.state.items.length * 2) % (this.state.cols || 36),
          12,
          16,
          11,
          13
        );
        break;
        case "FLR":
          this.setState({
            newFluidityRectangleCounter:
              this.state.newFluidityRectangleCounter + 1,
          });
          itemToAdd = new FluidityRectangleModel(
            "FLR" + this.state.newFluidityRectangleCounter,
            (this.state.items.length * 2) % (this.state.cols || 36),
            102,
           78,
            1,
            1,
            102,
            78,
            "#FFFFFF",
            "",
            "#999999",
            "Montserrat",
            38,
            ""
          );
          break; 
      case "FLL":
        this.setState({
          newFluidityLineCounter: this.state.newFluidityLineCounter + 1,
        });
        itemToAdd = new FluidityLineModel(
          "FLL" + this.state.newFluidityLineCounter,
          (this.state.items.length * 2) % (this.state.cols || 36),
          10,
          100,
          7,
          26
        );
        break;
      case "FGP":
        this.setState({
          newFoodGroupLandCounter: this.state.newFoodGroupLandCounter + 1,
        });
        itemToAdd = new FoodGroupModel(
          "FGP" + this.state.newFoodGroupLandCounter,
          (this.state.items.length * 2) % (this.state.cols || 36),
          180,
          108,
          45,
          108,
          108,
          180,
          "#F6F6F6",
          1,
          0,
          "#323232",
          "#17c1c1",
          "Montserrat",
          "Montserrat",
          25,
          25,
          25,
          25,
          25,
          25,
          25,
          25,
          25,
          25,
          25,
          "#323232",
          "#F6F6F6",
          "Montserrat",
          "",
          1
        );
        break;
      case "TTX":
        this.setState({
          newTitleTextCounter: this.state.newTitleTextCounter + 1,
        });
        itemToAdd = new TitleTextModel(
          "TTX" + this.state.newTitleTextCounter,
          (this.state.items.length * 2) % (this.state.cols || 36),
          108,
          192,
          15,
          15
        );
        break;
      case "MFG":
        this.setState({
          newMultiFoodGroupCounter: this.state.newMultiFoodGroupCounter + 1,
        });
        itemToAdd = new MultiFoodGroupModel(
          "MFG" + this.state.newMultiFoodGroupCounter,
          (this.state.items.length * 2) % (this.state.cols || 36),
          190,
          108,
          160,
          108
        );
        break;
    }
    this.setState({
      layout: this.state.layout.concat(itemToAdd),
      items: this.state.items.concat(itemToAdd),
    });
  };

  createElement(el) {
    console.log(el.zIndex);
    switch (el.i.substring(0, 3)) {
      case "BAC":
        return (
          <div
            key={el.i}
            style={{ zIndex: el.zIndex }}
            {...this.props}
            className={this.props.className}
            isDraggable={false}
            isBounded={false}
          >
            <Background
              onRemoveItem={this.onRemoveItem}
              item={el}
              onLayerChange={this.onLayerChange}
              onStyleChange={this.onStyleChange}
            />
          </div>
        );
      case "BAR":
        return (
          <div
            key={el.i}
            style={{ zIndex: el.zIndex }}
            {...this.props}
            className={this.props.className}
            isDraggable={false}
            isBounded={false}
          >
            <Bar
              onRemoveItem={this.onRemoveItem}
              item={el}
              onStyleChange={this.onStyleChange}
              onLayerChange={this.onLayerChange}
            />
          </div>
        );
      case "PRO":
        return (
          <div
            key={el.i}
            style={{ zIndex: el.zIndex }}
            {...this.props}
            className={this.props.className}
            isDraggable={false}
            isBounded={false}
          >
            <Promotion
              onRemoveItem={this.onRemoveItem}
              item={el}
              onLayerChange={this.onLayerChange}
              layout={this.state.layout}
            />
          </div>
        );
      case "PRV":
        return (
          <div
            key={el.i}
            style={{ zIndex: el.zIndex }}
            {...this.props}
            className={this.props.className}
            isDraggable={false}
            isBounded={false}
          >
            <PromoVertical
              onRemoveItem={this.onRemoveItem}
              item={el}
              onLayerChange={this.onLayerChange}
              layout={this.state.layout}
            />
          </div>
        );
      case "LOG":
        return (
          <div
            key={el.i}
            style={{ zIndex: el.zIndex }}
            {...this.props}
            className={this.props.className}
            isDraggable={false}
            isBounded={false}
          >
            <Logo
              onRemoveItem={this.onRemoveItem}
              item={el}
              onLayerChange={this.onLayerChange}
              onStyleChange={this.onStyleChange}
            />
          </div>
        );
      case "IMG":
        return (
          <div
            key={el.i}
            style={{ zIndex: el.zIndex }}
            {...this.props}
            className={this.props.className}
            isDraggable={false}
            isBounded={false}
          >
            <ImageComponent
              onRemoveItem={this.onRemoveItem}
              item={el}
              onStyleChange={this.onStyleChange}
              onLayerChange={this.onLayerChange}
            />
          </div>
        );
      case "TIT":
        return (
          <div
            key={el.i}
            style={{ zIndex: el.zIndex }}
            {...this.props}
            className={this.props.className}
            isDraggable={false}
            isBounded={false}
          >
            <TitleBar
              onRemoveItem={this.onRemoveItem}
              item={el}
              onLayerChange={this.onLayerChange}
              onStyleChange={this.onStyleChange}
              layout={this.state.layout}
            />
          </div>
        );
      case "MLH":
        return (
          <div
            key={el.i}
            style={{ zIndex: el.zIndex }}
            {...this.props}
            className={this.props.className}
            isDraggable={false}
            isBounded={false}
          >
            <HorizontalLine
              onRemoveItem={this.onRemoveItem}
              item={el}
              onLayerChange={this.onLayerChange}
              onStyleChange={this.onStyleChange}
            />
          </div>
        );
      case "MLV":
        return (
          <div
            key={el.i}
            style={{ zIndex: el.zIndex }}
            {...this.props}
            className={this.props.className}
            isDraggable={false}
            isBounded={false}
          >
            <VerticalLine
              onRemoveItem={this.onRemoveItem}
              item={el}
              onLayerChange={this.onLayerChange}
              onStyleChange={this.onStyleChange}
            />
          </div>
        );
      case "DAT":
        return (
          <div
            key={el.i}
            style={{ zIndex: el.zIndex }}
            {...this.props}
            className={this.props.className}
            isDraggable={false}
            isBounded={false}
          >
            <DateAndTime
              onRemoveItem={this.onRemoveItem}
              item={el}
              onLayerChange={this.onLayerChange}
              onStyleChange={this.onStyleChange}
            />
          </div>
        );
      case "SAN":
        return (
          <div
            key={el.i}
            style={{ zIndex: el.zIndex }}
            {...this.props}
            className={this.props.className}
            isDraggable={false}
            isBounded={false}
          >
            <Saint
              onRemoveItem={this.onRemoveItem}
              item={el}
              onLayerChange={this.onLayerChange}
              onStyleChange={this.onStyleChange}
            />
          </div>
        );
      case "WEA":
        return (
          <div
            key={el.i}
            style={{ zIndex: el.zIndex }}
            {...this.props}
            className={this.props.className}
            isDraggable={false}
            isBounded={false}
          >
            <Weather
              onRemoveItem={this.onRemoveItem}
              item={el}
              onLayerChange={this.onLayerChange}
              onStyleChange={this.onStyleChange}
            />
          </div>
        );
      case "FLL":
        return (
          <div
            key={el.i}
            style={{ zIndex: el.zIndex }}
            {...this.props}
            className={this.props.className}
            isDraggable={false}
            isBounded={false}
          >
            <FluidityLine
              onRemoveItem={this.onRemoveItem}
              item={el}
              onLayerChange={this.onLayerChange}
              onStyleChange={this.onStyleChange}
            />
          </div>
        );
        case "FLR":
          return (
            <div
              key={el.i}
              style={{ zIndex: el.zIndex }}
              {...this.props}
              className={this.props.className}
              isDraggable={false}
              isBounded={false}
             // className="noresize"
            >
              <FluidityRectangle_Port
                onRemoveItem={this.onRemoveItem}
                item={el}
                onLayerChange={this.onLayerChange}
                onStyleChange={this.onStyleChange}
                onFluidityRectangleSizeChange={this.onFluidityRectangleSizeChange}
              />
            </div>
          );
      case "FGP":
        return (
          <div
            key={el.i}
            style={{ zIndex: el.zIndex }}
            {...this.props}
            className={this.props.className}
            isDraggable={false}
            isBounded={false}
            //className="noresize"
          >
            <FoodGroup_Port
              onRemoveItem={this.onRemoveItem}
              item={el}
              onLayerChange={this.onLayerChange}
              onStyleChange={this.onStyleChange}
              onFoodGroupLandSizeChange={this.onFoodGroupLandSizeChange}
            />
          </div>
        );
      case "TTX":
        return (
          <div
            key={el.i}
            style={{ zIndex: el.zIndex }}
            {...this.props}
            className={this.props.className}
            isDraggable={false}
            isBounded={false}
          >
            <TitleText
              onRemoveItem={this.onRemoveItem}
              item={el}
              onStyleChange={this.onStyleChange}
              onLayerChange={this.onLayerChange}
            />
          </div>
        );
      case "MFG":
        return (
          <div
            key={el.i}
            style={{ zIndex: el.zIndex }}
            {...this.props}
            className={this.props.className}
            isDraggable={false}
            isBounded={false}
          >
            <MultiFoodGroup_Port
              onRemoveItem={this.onRemoveItem}
              item={el}
              onLayerChange={this.onLayerChange}
              onStyleChange={this.onStyleChange}
            />
          </div>
        );
    }
  }

  onRemoveItem = (i) => {
    console.log("removing", i);
    this.setState({ layout: _.reject(this.state.layout, { i: i }) });
    this.setState({ items: _.reject(this.state.items, { i: i }) });
  };

  onResize = (items) => {
    items.forEach((item) => {
      if (item.i.substring(0, 3) === "LOG") {
        let start_height = 80;
        let start_width = 80;
        let ratio = start_width / start_height;
        item.w = item.h;
        item.minW = item.h * ratio;
        item.maxW = item.h * ratio;
      } else if (
        item.i.substring(0, 3) === "DAT" ||
        item.i.substring(0, 3) === "WEA"
      ) {
        if (item.h === 11) {
          item.w = 13;
          item.maxW = 13;
          item.minW = 13;
        } else if (item.h === 12) {
          item.w = 16;
          item.minW = 16;
          item.maxW = 16;
        }
      } else if (item.i.substring(0, 3) === "SAN") {
        if (item.h === 5) {
          item.w = 26;
          item.maxW = 26;
          item.minW = 26;
        } else if (item.h === 6) {
          item.w = 32;
          item.minW = 32;
          item.maxW = 32;
        }
      } else if (item.i.substring(0, 3) === "PRO") {
        let start_height = 9;
        let start_width = 16;
        let ratio = start_width / start_height;
        item.w = item.h * ratio;
        item.minW = item.h * ratio;
        item.maxW = item.h * ratio;
      } else if (item.i.substring(0, 3) === "PRV") {
        let start_height = 16;
        let start_width = 9;
        let ratio = start_height / start_width;
        item.h = item.w * ratio;
        item.minH = item.w * ratio;
        item.maxH = item.w * ratio;
      }
    });
  };
  onFluidityRectangleSizeChange = (item, widht, height) => {
    for (var i = 0; i < this.state.items.length; i++) {
      if (this.state.items[i].i === item.i) {
        this.setState({
          newFluidityRectangleCounter:
            this.state.newFluidityRectangleCounter + 1,
        });
        this.setState({ items: this.state.items.splice(i, 1) });
        this.setState({ layout: this.state.layout.splice(i, 1) });
        let newRectangle = new FluidityRectangleModel(
          "FLR" + this.state.newFluidityRectangleCounter,
          item.x,
          69,
          163,
          1,
          1,
          widht,
          height,
          item.background,
          item.zona,
          item.textColor,
          item.textFont,
          item.textSize,
          item.image
        );
        this.setState({ items: this.state.items.concat(newRectangle) });
        this.setState({ layout: this.state.layout.concat(newRectangle) });
        break;
      }
    }
  };

  onFoodGroupLandSizeChange = (item, widht, height, typeSize) => {
    for (var i = 0; i < this.state.items.length; i++) {
      if (this.state.items[i].i === item.i) {
        this.setState({
          newFoodGroupLandCounter: this.state.newFoodGroupLandCounter + 1,
        });
        this.setState({ items: this.state.items.splice(i, 1) });
        this.setState({ layout: this.state.layout.splice(i, 1) });
        let newFoodGroupLand = new FoodGroupModel(
          "FGP" + this.state.newFoodGroupLandCounter,
          item.x,
          180,
          108,
          45,
          108,
          widht,
          height,
          item.background,
          item.alpha,
          item.id,
          item.colorPrimary,
          item.colorAccent,
          item.fontFamily,
          item.fontFamilySecondary,
          item.fontSizeTitle,
          item.fontSizeTitleNi,
          item.fontSizeTitleSec,
          item.fontSizeTitleSecNi,
          item.fontSizeDescription,
          item.fontSizeDescriptionNi,
          item.fontSizeDescriptionSec,
          item.fontSizeDescriptionSecNi,
          item.fontSizeOrigin,
          item.fontSizeCalories,
          item.fontSizePrice,
          item.colorPrice,
          item.colorPriceTx,
          item.fontFamilyPrice,
          item.headerImage,
          typeSize
        );
        this.setState({ items: this.state.items.concat(newFoodGroupLand) });
        this.setState({ layout: this.state.layout.concat(newFoodGroupLand) });
        break;
      }
    }
  };

  render() {
    const { redirectForbidden } = this.state;
    if (redirectForbidden) {
      return <Redirect to="/" />;
    }

    return (
      <div>
        <Header
          items={this.state.items}
          layout={this.state.layout}
          onSelectTemplate={this.onSelectTemplate}
          previewLink="previewportrait"
        />

        <Toolbox items={this.state.toolboxItems} onTakeItem={this.onTakeItem} />
        <div className="container_gridlayout">
          <GridLayout
            {...this.props}
            className="layout portrait"
            layout={this.state.layout}
            onLayoutChange={this.onLayoutChange}
            width={1080}
            rowHeight={10}
            maxRows={192}
            cols={108}
            verticalCompact={false}
            preventCollision={true}
            margin={[0, 0]}
            containerPadding={[0, 0]}
            autoSize
            onResize={(items) => this.onResize(items)}
          >
            {this.state.items.map((el) => this.createElement(el))}
          </GridLayout>
        </div>
        {/*   <button onClick={() => console.log(this.state.items)}>DEBUG</button>
        <button onClick={() => console.log(this.state.layout)}>DEBUG2</button> */}
      </div>
    );
  }
}

export default EditorLandscape;
