import React from "react";
import {
  Button,
  ButtonToolbar,
  ButtonGroup,
  Modal,
  Form,
  Col,
  Row,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faTimes } from "@fortawesome/free-solid-svg-icons";

import FontList from "../../styles/FontList";

const listFont = FontList;
class Weather extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show_delete: false,
      show_modify: false,
      color_bg: this.props.item.background,
      color_bg_canc: this.props.item.background,
      color_text: this.props.item.color,
      color_text_canc: this.props.item.color,
      fontfamily: this.props.item.textFont,
      fontfamily_canc: this.props.item.textFont,
      fontsize: this.props.item.textSize,
      fontsize_canc: this.props.item.textSize,
      switchbackground: true,
      iconSize: this.props.item.iconSize,
      zindex: this.props.item.zIndex,
      zindex_canc: this.props.item.zIndex,
      Curved_tb: this.props.item.borderRadius,
      Curved_tb_canc: this.props.item.borderRadius,
    };
    this.handleChangeColorBg = this.handleChangeColorBg.bind(this);
    this.handleChangeColorText = this.handleChangeColorText.bind(this);
    this.handleChangeFontFamily = this.handleChangeFontFamily.bind(this);
    this.handleChangeFontSize = this.handleChangeFontSize.bind(this);
    this.handleChangeIndex = this.handleChangeIndex.bind(this);
    this.handleChangeCurved = this.handleChangeCurved.bind(this);
  }
  //MODAL DELETE
  //Close
  handleCloseDelete = () => {
    this.setState({ show_delete: false });
  };
  //Open
  handleShowDelete = () => {
    this.setState({ show_delete: true });
  };
  //MODAL MODIFY
  //Close
  handleCloseModify = () => {
    this.setState({ show_modify: false });
  };
  //Open
  handleShowModify = () => {
    this.setState({ show_modify: true });
  };
  //Cahnge Color Background
  handleChangeColorBg(event) {
    this.setState({ color_bg: event.target.value });
  }
  //Transparent
  handleswitchBackground = (event) => {
    this.setState({ switchbackground: event.target.checked });
    if (this.state.switchbackground === true) {
      this.setState({ color_bg: "transparent" });
    } else {
      this.setState({ color_bg: this.state.color_bg_canc });
    }
  };
  //Cahnge Color Text
  handleChangeColorText(event) {
    this.setState({ color_text: event.target.value });
  }
  //Cahnge Font Size
  handleChangeFontSize(event) {
    if (event.target.value > 22) {
      this.setState({ fontsize: 22 });
    } else if (event.target.value < 17) {
      this.setState({ fontsize: 17 });
    } else {
      this.setState({ fontsize: event.target.value });
    }
  }
  //Change Font Family
  handleChangeFontFamily(event) {
    this.setState({ fontfamily: event.target.value });
  }

  iconSize(sizeIcon) {
    switch (sizeIcon) {
      case "small":
        this.setState({ iconSize: "42px" });
        break;
      case "big":
        this.setState({ iconSize: "47px" });
        break;
    }
  }
      //Change Curved
  handleChangeCurved(event) {
        this.setState({ Curved_tb: event.target.value });

  }
  //Change Index
  handleChangeIndex(event) {
    if (event.target.value > 999) {
      this.setState({ zindex: 999 });
    } else if (event.target.value < 1) {
      this.setState({ zindex: 1 });
    } else {
      this.setState({ zindex: event.target.value });
    }
    this.props.onLayerChange(this.props.item.i, event.target.value);
  }

  //Cancel Changes
  cancelOnModal = () => {
    this.setState({ color_bg: this.state.color_bg_canc });
    this.setState({ zindex: this.state.zindex_canc });
    this.setState({ color_text: this.state.color_text_canc });
    this.setState({ fontfamily: this.state.fontfamily_canc });
    this.setState({ fontsize: this.state.fontsize_canc });
    this.setState({ Curved_tb: this.state.Curved_tb_canc });
    this.props.onLayerChange(this.props.item.i, this.state.zindex_canc);
    this.handleCloseModify();
  };
  //Confirm Changes
  confirmChangeonModal = () => {
    this.setState({ color_bg_canc: this.state.color_bg });
    this.setState({ color_text_canc: this.state.color_text });
    this.setState({ fontfamily_canc: this.state.fontfamily });
    this.setState({ zindex_canc: this.state.zindex });
    this.setState({ fontsize_canc: this.state.fontsize });
    this.setState({ borderRadius_canc: this.state.borderRadius });
    this.props.item.background = this.state.color_bg;
    this.props.item.color = this.state.color_text;
    this.props.item.textFont = this.state.fontfamily;
    this.props.item.textSize = this.state.fontsize;
    this.props.item.iconSize = this.state.iconSize;
    this.props.item.borderRadius = this.state.Curved_tb;
    this.props.onStyleChange(this.props.item);

    this.handleCloseModify();
  };
  borderRadiusContainer() {
    if (this.state.Curved_tb===undefined)
    {
      this.state.Curved_tb=0
     }
      let borderRadiusContainer = this.state.Curved_tb;
        return borderRadiusContainer;
  }
  render() {
    const divStile = {
      overflow: "hidden",
      padding: "0",
      backgroundColor: this.state.color_bg,
      width: "100%",
      height: "100%",
      fontFamily: this.state.fontfamily,
      color: this.state.color_text,
      fontSize: this.state.fontsize + "px",
      border: " 1px solid #1e1e338c",
      borderRadius: this.borderRadiusContainer() +"px",
    };
    const IconStyle = {
      fill: this.state.color_text,
      height: this.state.iconSize,
      lineHeight: "0",
      margin: "0",
    };
    const weatherStyle = {
      fontSize: this.state.fontsize + "px",
      margin: "0",
    };
    return (
      <>
        <div className="container_component">
          <Button
            variant="primary"
            className="circlebutton"
            onClick={this.handleShowModify}
          >
            <FontAwesomeIcon icon={faPencilAlt} />
          </Button>

          <Button
            className="circlebutton"
            variant="danger"
            onClick={this.handleShowDelete}
          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>
          <div style={divStile}>
            <h1 style={IconStyle} className="iconWeather">
              <svg viewBox="0 0 397.401 397.401" width="1em" height="1em">
                <path d="M173.573 97.768l-21.88-22.12a7.28 7.28 0 1 0-10.32 10.2l21.88 22.12a7.28 7.28 0 0 0 10.32-10.2zM359.085 76.793a7.28 7.28 0 0 0-11.112-.065l-22.12 21.88a7.28 7.28 0 1 0 10.2 10.32l22.12-21.88a7.28 7.28 0 0 0 .912-10.255zM227.256 72.236a7.146 7.146 0 0 0-.163-.628l-8-30.08a7.241 7.241 0 1 0-14.001 3.64l8 30.08a7.241 7.241 0 0 0 14.164-3.012zM326.733 227.128a101.162 101.162 0 0 0-4.24-4l1.2-2.16a84.05 84.05 0 0 0 4-8.44c17.039-42.555-3.646-90.866-46.201-107.905l-.239-.095c-38.472-15.335-82.362.024-102.88 36-50.15-14.006-102.159 15.294-116.166 65.445a94.234 94.234 0 0 0-3.474 25.635v3.76C23.916 237.837-2.308 268.063.161 302.88c2.333 32.907 29.584 58.483 62.572 58.728h208.2c43.498.075 78.821-35.125 78.897-78.623a78.763 78.763 0 0 0-23.097-55.857zm-101.44-109.08a68.359 68.359 0 0 1 50.52 0c35.167 14.031 52.331 53.889 38.36 89.08a69.394 69.394 0 0 1-3.44 7.08v.64a78.84 78.84 0 0 0-66.16-6.08 94.154 94.154 0 0 0-23.84-42.76c8.089-15.018 26.11-21.643 42-15.44a7.24 7.24 0 0 0 5.32-13.44c-21.575-8.645-46.243-.591-58.56 19.12a94.49 94.49 0 0 0-17.56-10.44 68.403 68.403 0 0 1 33.36-27.76zm60.835 227.241a64.28 64.28 0 0 1-15.115 1.76h-208c-26.885-.024-48.66-21.839-48.636-48.724a48.682 48.682 0 0 1 14.236-34.356l-.08.04a48.52 48.52 0 0 1 34.4-14.24H66.253a7.24 7.24 0 0 0 7.6-8.6 92.687 92.687 0 0 1-.4-4.52v-5.08c.593-44.046 36.78-79.272 80.827-78.679 38.703.521 71.445 28.754 77.653 66.959a7.24 7.24 0 0 0 10.4 5.44 63.768 63.768 0 0 1 13.92-5.04c34.529-8.25 69.208 13.054 77.458 47.583 8.25 34.528-13.054 69.207-47.583 77.457zM397.077 145.396a7.24 7.24 0 0 0-8.425-4.948l-30.08 8a7.24 7.24 0 0 0-5.2 8.8 7.24 7.24 0 0 0 8.84 5.2l30.08-8a7.238 7.238 0 0 0 4.785-9.052zM391.973 216.168l-30-8.2a7.26 7.26 0 1 0-4 13.96l30 8.2a7.26 7.26 0 1 0 4-13.96zM289.753 36.668a7.26 7.26 0 0 0-8.98 4.98l-8.2 30a7.24 7.24 0 0 0 5.12 9.08 7.24 7.24 0 0 0 9.04-5.08l8-30a7.261 7.261 0 0 0-4.98-8.98z"></path>
              </svg>
            </h1>
            <h3 style={weatherStyle}>
              <strong>Peu Nuageux</strong>
              <br />
              5 C°
            </h3>
          </div>
        </div>

        {/* MODAL MODIFY */}
        <div onMouseDown={(e) => e.stopPropagation()}>
          <Modal
           fade={false}
           animation={false}
            className="sideMenu"
            show={this.state.show_modify}
            onHide={this.cancelOnModal}
            backdrop="static"
            keyboard={false}
            scrollable={true}
            backdropClassName="backdrop_sideMenu"
          >
            <Modal.Header closeButton>
              <Modal.Title>Change Weather</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                {/* BACKGROUND COLOR */}
                <Row>
                  <Col md={10}>
                    <Form.Group>
                      <Form.Label>Color Background</Form.Label>

                      <Col md={12} className="my-2">
                        <Form.Check
                          checked={this.state.switchbackground}
                          onChange={this.handleswitchBackground}
                          type="switch"
                          id="custom-switch"
                          label="Background Color"
                        />
                      </Col>

                      <Col md={6}>
                        <Form.Control
                          disabled={!this.state.switchbackground}
                          type="color"
                          value={this.state.color_bg}
                          onChange={this.handleChangeColorBg}
                        />
                        <Form.Control
                          disabled={!this.state.switchbackground}
                          className="my-2"
                          type="text"
                          value={this.state.color_bg}
                          onChange={this.handleChangeColorBg}
                        />

                        <Form.Text className="text-muted">
                          Color select: {this.state.color_bg}
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>

                {/* ICON SIZE */}
                <Row>
                  <Col md={10}>
                    <Form.Group>
                      <Form.Label>Icon Size</Form.Label>
                      <Col md={6}>
                        <ButtonToolbar aria-label="Toolbar with button groups">
                          <ButtonGroup
                            className="mr-2"
                            aria-label="First group"
                          >
                            <Button
                              variant="primary"
                              onClick={this.iconSize.bind(this, "small")}
                            >
                              Small
                            </Button>
                            <Button
                              variant="primary"
                              onClick={this.iconSize.bind(this, "big")}
                            >
                              Big
                            </Button>
                          </ButtonGroup>
                        </ButtonToolbar>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  {/* FONTFAMILY */}
                  <Col md={10}>
                    <Form.Group>
                      <Form.Label>Font family</Form.Label>
                      <Col md={10}>
                        <Form.Control
                          as="select"
                          onChange={this.handleChangeFontFamily}
                          value={this.state.fontfamily}
                        >
                          {listFont.map((font) => {
                            return (
                              <option
                                value={font.font}
                                style={{ fontFamily: font.font }}
                              >
                                {font.name}
                              </option>
                            );
                          })}
                        </Form.Control>
                        <Form.Text className="text-muted">
                          {this.state.text}
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                {/* FONT SIZE TITLE*/}
                <Row>
                  <Col md={10}>
                    <Form.Group>
                      <Form.Label>Font size</Form.Label>
                      <Col md={6}>
                        <Form.Control
                          type="number"
                          value={this.state.fontsize}
                          onChange={this.handleChangeFontSize}
                          min="17"
                          max="22"
                          onKeyPress={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                        />
                        <Form.Text className="text-muted">
                          font-size: {this.state.fontsize}
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>

                {/* Color Text */}
                <Row>
                  <Col md={10}>
                    <Form.Group>
                      <Form.Label>Color Text</Form.Label>
                      <Col md={6}>
                        <Form.Control
                          type="color"
                          value={this.state.color_text}
                          onChange={this.handleChangeColorText}
                        />
                        <Form.Control
                          className="my-2"
                          type="text"
                          value={this.state.color_text}
                          onChange={this.handleChangeColorText}
                        />

                        <Form.Text className="text-muted">
                          Color select: {this.state.color_text}
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={10}>
                    <Form.Group>
                      <Form.Label>Z-Index</Form.Label>
                      <Col md={6}>
                        <Form.Control
                          type="number"
                          value={this.state.zindex}
                          onChange={this.handleChangeIndex}
                          min="1"
                          max="999"
                          onKeyPress={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                        />
                        <Form.Text className="text-muted">
                          min:1 max:999 <br />
                          z-index: {this.state.zindex}
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
               {/* Curved Edges*/}
                <Row>
                  <Col md={10}>
                    <Form.Group>
                      <Form.Label>Curved Edges Pixels</Form.Label>
                      <Col md={6}>
                        <Form.Control
                          type="number"
                          value={this.state.Curved_tb}
                          onChange={this.handleChangeCurved}
                          min="1"
                          max="999"
                          onKeyPress={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                        />
                        <Form.Text className="text-muted">
                          min:1 max:999 <br />
                          Curved Edges Pixels: {this.state.Curved_tb}
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.cancelOnModal}>
                Cancel
              </Button>
              <Button variant="primary" onClick={this.confirmChangeonModal}>
                Ok
              </Button>
            </Modal.Footer>
          </Modal>
        </div>

        {/* MODAL DELETE */}
        <div onMouseDown={(e) => e.stopPropagation()}>
          <Modal
            show={this.state.show_delete}
            onHide={this.handleCloseDelete}
            className="modal_dark"
            backdropClassName="backdrop_modal"
          >
            <Modal.Header closeButton>
              <Modal.Title>Delete Weather</Modal.Title>
            </Modal.Header>
            <Modal.Body>wonna delete?</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.handleCloseDelete}>
                Close
              </Button>
              <Button
                onClick={() => this.props.onRemoveItem(this.props.item.i)}
                variant="danger"
              >
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </>
    );
  }
}

export default Weather;
