import React from "react";
import { Image } from "react-bootstrap";
import ImageFoodGroup from "../toolboxComponents/foodGroupComponent/ImageFoodGroup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarker } from "@fortawesome/free-solid-svg-icons";
import { getfooditemsbyfoodgroupID } from "../../api/Api";
import * as allergensIcons from "../../styles/allergens";

class FoodGroup_Land extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type_size: this.props.item.typeSize,
      foodgroup: [],
    };
  }

  //get MAP FROM DISPLAY ID

  componentDidMount() {
    this.getFoodItems();
    this.interval = setInterval(this.getFoodItems.bind(this), 180 * 10000);
  }
  componentWillUnmount() {
    // Clear the interval right before component unmount
    clearInterval(this.interval);
  }

  getFoodItems = () => {
    getfooditemsbyfoodgroupID(this.props.item.id).then((res) => {
      if (res == null) {
        this.setState({ foodgroup: [] });
      } else if (res[0]) {
        this.setState({ foodgroup: res });
      } else {
        this.setState({ foodgroup: [] });
      }
    });
  };

  //get Icon Allergens
  getIconSvg(allergen) {
    let allergenIcon = allergen
      .split(" ")
      .join("")
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
    if (allergen) {
      const svgIndex = "Svg" + allergenIcon;
      return allergensIcons[svgIndex];
    }
    return null;
  }

  //get WIDTH FLEX
  getwidthItems() {
    let activeFoodItem = this.state.foodgroup.filter(function (value) {
      return value.active !== false;
    }).length;  
    let flex = " 33%";
    if (this.props.item.switchDiamondbackground === true) {
      switch (activeFoodItem) {
        case 1:
          flex = "1237.39px";
          //heightItems = "90%";
          break;
        case 2:
          flex = "629px";
         // heightItems = "40.0%";
          break;
        case 3:
          flex = "887px";
         // heightItems = "26.67%";
          break;
        case 4:
          flex = "887px";
           // heightItems = "20%";
            break;
        case 5:
          flex = "887px";
           // heightItems = "16%";
            break;
        default:
          flex = " 90%";
      }
    
    } else {
       flex = " 25%";
       if (this.state.type_size === 1) {
          if (activeFoodItem > 6) {
           flex = " 20%";
         } else if (activeFoodItem <= 4) {
        flex = " 40%";
         } else if (activeFoodItem <= 6) {
        flex = " 30%";
          }
    } else if (this.state.type_size === 2) {
      if (activeFoodItem > 2) {
        flex = " 40%";
      } else if (activeFoodItem <= 2) {
        flex = " 55%";
      }
    } else if (this.state.type_size === 3) {
      flex = " 95%";
    } else if (this.state.type_size === 4) {
      flex = " 40%";
    } else if (this.state.type_size === 5) {
      flex = " 10%";
    }
  }
    return flex;
  }

  //get max number of Food item
  getmaxFoodItem() {
    let slice = 8;
    if (this.state.switchDiamondbackground === true) {
      if (this.state.type_size === 1) {
        slice = 8;
      } else if (this.state.type_size === 2) {
        slice = 5;
      } else if (this.state.type_size === 3) {
        slice = 5;
      } else if (this.state.type_size === 4) {
        slice = 5;
      } else if (this.state.type_size === 5) {
        slice = 5;
      } 
  }
  else 
  {
    if (this.state.type_size === 1) {
      slice = 8;
    } else if (this.state.type_size === 2) {
      slice = 4;
    } else if (this.state.type_size === 3) {
      slice = 2;
    } else if (this.state.type_size === 4) {
      slice = 2;
    } else if (this.state.type_size === 5) {
      slice = 4;
    }
  }
    return slice;
  }
  getHeightItems() {
    let activeFoodItem =
      this.state.foodgroup.filter(function (value) {
        return value.active !== false;
      }).length;
     
      let heightItems = "400px";
    if (this.props.item.switchDiamondbackground === true) {
        
      switch (activeFoodItem) {
        case 1:
          heightItems = "340px";
          //heightItems = "90%";
          break;
        case 2:
          heightItems = "277px";
         // heightItems = "40.0%";
          break;
        case 3:
          heightItems = "201px";
         // heightItems = "26.67%";
          break;
        case 4:
          heightItems = "180px";
           // heightItems = "20%";
            break;
        case 5:
           heightItems = "150px";
           // heightItems = "16%";
            break;
      }

      } else {
           if (this.state.type_size === 1 && activeFoodItem <= 2) {
            heightItems="85%"
           }
           else { heightItems ="400px" }
      }
      
      return heightItems
  }


  getMaxWidthItems() {
    let activeFoodItem =
      this.state.foodgroup.filter(function (value) {
        return value.active !== false;
      }).length;

    if (this.state.type_size === 1) {
      if (activeFoodItem === 3) {
        return " calc(50% - 60px)";
      } else {
        return " auto";
      }
    }
  }
  //Capitalize
  capitalize(s) {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
  }
  borderRadiusContainer() {
    if (this.props.item.borderRadius===undefined)
      {
        this.props.item.borderRadius=0
       }
      let borderRadiusContainer = this.props.item.borderRadius
        return borderRadiusContainer;
  }
  GetVerticalLineMargin() {
    //alert(this.state.Curved_tb);
    if (this.props.item.widthSize<100)
    {
      return "80";
     }
     else
     {return "89.5";}

       
  }
  GetVerticalLineHeight() {
    //alert(this.state.Curved_tb);
    if (this.props.item.widthSize<100)
    {
      return "75";
     }
     else
     {return "75";}

       
  }
  GetVerticalLineMarginTop() {
    //alert(this.state.Curved_tb);
    let activeFoodItem = this.state.foodgroup.filter(function (value) {
      return value.active !== false;
    }).length;  
    let MarginTop = "30";
    console.log(activeFoodItem);
    if (this.props.switchDiamondbackground === true) {
      switch (activeFoodItem) {
        case 1:
          MarginTop =  "1";
          
          break;
        case 2:
          MarginTop =  "0.5";
         // heightItems = "40.0%";
          break;
        case 3:
          MarginTop =  "0.5";
         // heightItems = "26.67%";
          break;
        case 4:
          MarginTop =  "0.5";
           // heightItems = "20%";
            break;
        case 5:
          MarginTop =  "0.5";
           // heightItems = "16%";
            break;
        default:
          MarginTop =  "1";
        
    }
  } else {
    MarginTop =  "1";
  }
  console.log("MarginTop");
  console.log(MarginTop);
  return MarginTop;
   /* if (this.props.item.widthSize<100)
    {
      return "3";
     }
     else
     {return "1";}*/

       
  }
       
  
  GetBorderStyleAllergen() {
    //alert(this.state.Curved_tb);
    if (this.props.item.switchDiamondbackground === false)
    {
      return "tagImageStyle";
     }
     else
     {return "tagImageStyle_AGR";}

       
  }
  GetPriceAdjustment(PriceValue) {
    //alert(this.state.Curved_tb);
    if (this.props.switchDiamondbackground === true && PriceValue < 10)
    {
      return "0" + PriceValue;
     }
     else
     {return PriceValue;}

       
  }

       
  getFoodStyleMarginItems() {
    //alert(this.state.Curved_tb);
    console.log("getFoodStyleMarginItems")
    let activeFoodItem = this.state.foodgroup.filter(function (value) {
      return value.active !== false;
    }).length;  
    let FoodStyleMarginItems = " auto 30px";
    console.log(activeFoodItem);
    if (this.state.switchDiamondbackground === true) {
      switch (activeFoodItem) {
        case 1:
          FoodStyleMarginItems =  " auto 30px";
          
          break;
        case 2:
          FoodStyleMarginItems =  " 15px 30px";
         // heightItems = "40.0%";
          break;
        case 3:
          FoodStyleMarginItems =  " 15px 30px";
         // heightItems = "26.67%";
          break;
        case 4:
          FoodStyleMarginItems =  " 15px 30px";
           // heightItems = "20%";
            break;
        case 5:
          FoodStyleMarginItems =  "15px 30px";
           // heightItems = "16%";
            break;
        default:
          FoodStyleMarginItems =  " auto 30px";
        
    }
  } else {
    FoodStyleMarginItems =  " auto 30px";
  }
  console.log("FoodStyleMarginItems");
  console.log(FoodStyleMarginItems);
  return FoodStyleMarginItems;
 /*   if (this.props.item.widthSize<100)
    {
    return " auto 30px";//" 15px 30px";
  } else {
    return " 15px 30px";
  }*/
// }
}

getHexagonStyleBottomMargin() {

let activeFoodItem = this.state.foodgroup.filter(function (value) {
  return value.active !== false;
}).length;  
let BottomMargin = "30";
console.log(activeFoodItem);
/*if (this.state.switchDiamondbackground === true) {
  switch (activeFoodItem) {
    case 1:
      BottomMargin =  "30";
      
      break;
    case 2:
      BottomMargin =  "17";
     // heightItems = "40.0%";
      break;
    case 3:
      BottomMargin =  "13";
     // heightItems = "26.67%";
      break;
    case 4:
      BottomMargin =  "9";
       // heightItems = "20%";
        break;
    case 5:
      BottomMargin =  "6";
       // heightItems = "16%";
        break;
    default:
      BottomMargin =  "30";
    
}
} else {
BottomMargin =  "30";
}*/
if (this.props.switchDiamondbackground === true) {
if (this.props.item.widthSize<100)
  {
    switch (activeFoodItem) {
      case 1:
        BottomMargin =  "30";
        
        break;
      case 2:
        BottomMargin =  "27";
       // heightItems = "40.0%";
        break;
      case 3:
        BottomMargin =  "20";
       // heightItems = "26.67%";
        break;
      case 4:
        BottomMargin =  "17";
         // heightItems = "20%";
          break;
      case 5:
        BottomMargin =  "16";
         // heightItems = "16%";
          break;
      default:
        BottomMargin =  "30";
    }
  }
else
{
  switch (activeFoodItem) {
    case 1:
      BottomMargin =  "30";
      
      break;
    case 2:
      BottomMargin =  "20";
     // heightItems = "40.0%";
      break;
    case 3:
      BottomMargin =  "13";
     // heightItems = "26.67%";
      break;
    case 4:
      BottomMargin =  "9";
       // heightItems = "20%";
        break;
    case 5:
      BottomMargin =  "6";
       // heightItems = "16%";
        break;
    default:
      BottomMargin =  "30";
  }
    
}
}else {
  BottomMargin =  "30";
}
console.log("BottomMargin");
console.log(BottomMargin);
return BottomMargin;
}



containerPricesStyleRight() {

let activeFoodItem = this.state.foodgroup.filter(function (value) {
  return value.active !== false;
}).length;  
let PricesStyleRight = "35px";
console.log(activeFoodItem);
if (this.props.switchDiamondbackground === true) {
  if (this.props.item.widthSize<100)
    {
      switch (activeFoodItem) {
        case 1:
          PricesStyleRight =  "4.5%";
          
          break;
        case 2:
          PricesStyleRight =  "4.5%";
         // heightItems = "40.0%";
          break;
        case 3:
          PricesStyleRight =  "4.5%";
         // heightItems = "26.67%";
          break;
        case 4:
          PricesStyleRight =  "4.5%";
           // heightItems = "20%";
            break;
        case 5:
          PricesStyleRight =  "4.5%";
           // heightItems = "16%";
            break;
        default:
          PricesStyleRight =  "4.5%";
      }
    }
  else
  {
    switch (activeFoodItem) {
      case 1:
        PricesStyleRight =  "49px";
        
        break;
      case 2:
        PricesStyleRight =  "49px";
       // heightItems = "40.0%";
        break;
      case 3:
        PricesStyleRight =  "49px";
       // heightItems = "26.67%";
        break;
      case 4:
        PricesStyleRight =  "49px";
         // heightItems = "20%";
          break;
      case 5:
        PricesStyleRight =  "49px";
         // heightItems = "16%";
          break;
      default:
        PricesStyleRight =  "49px";
    }
      
  }
}

   else {
PricesStyleRight =  "35px";
}

console.log("PricesStyleRight");
console.log(PricesStyleRight);
return PricesStyleRight;
}

getHexagonStylePadding() {

let activeFoodItem = this.state.foodgroup.filter(function (value) {
  return value.active !== false;
}).length;  
let HexagonPadding = "35";
console.log(activeFoodItem);
if (this.props.switchDiamondbackground === true) {
  switch (activeFoodItem) {
    case 1:
      HexagonPadding =  "35";
      
      break;
    case 2:
      HexagonPadding =  "30";
     // heightItems = "40.0%";
      break;
    case 3:
      HexagonPadding =  "25";
     // heightItems = "26.67%";
      break;
    case 4:
      HexagonPadding =  "20";
       // heightItems = "20%";
        break;
    case 5:
      HexagonPadding =  "15";
       // heightItems = "16%";
        break;
    default:
      HexagonPadding =  "35";
    
}
} else {
HexagonPadding =  "35";
}
console.log("HexagonPadding");
console.log(HexagonPadding);
return HexagonPadding;
}

getFontAdjustment(myfontsize) {

let activeFoodItem = this.state.foodgroup.filter(function (value) {
  return value.active !== false;
}).length;  
let fontSize = myfontsize;
console.log(activeFoodItem);
if (this.props.switchDiamondbackground === true) {
  switch (activeFoodItem) {
    case 1:
      fontSize = myfontsize;
      
      break;
    case 2:
      fontSize = myfontsize;
     // heightItems = "40.0%";
      break;
    case 3:
      fontSize = myfontsize;
     // heightItems = "26.67%";
      break;
    case 4:
      fontSize = myfontsize;
       // heightItems = "20%";
        break;
    case 5:
      fontSize = myfontsize*0.75;
       // heightItems = "16%";
        break;
    default:
      fontSize = myfontsize;
    
}
} else {
fontSize = myfontsize;
}
console.log("fontSize");
console.log(fontSize);
return fontSize;
}

  render() {
    const divStyle = {
      height: "100%",
      width: "100%",
      transition: "all 250ms ease",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      borderRadius: this.borderRadiusContainer() +"px",
    };
    const VerticaldivStyle = {
      //margin: "0",
      borderRight: "2px solid",
      backgroundColor: this.props.item.color,
      //width: "2px",
      height: this.GetVerticalLineHeight() + "%",
     // float:"right",
     marginLeft: this.GetVerticalLineMargin() + "%",
     marginTop: this.GetVerticalLineMarginTop() + "%",
     position:"absolute"
    };
    const containerStyle = {
      padding: "10px",
      backgroundColor: this.props.item.background,
      height: "100%",
      width: "100%",
      display: "flex",
      flexWrap: "wrap",
      borderRadius:this.borderRadiusContainer() +"px",
    };

    const foodItemStyle = {
      position: "relative",
      height: this.getHeightItems(),
      backgroundColor: "#FFFFFF",
      padding: "0",
      margin: this.getFoodStyleMarginItems(),//"auto 30px",
      flex: "1 1" + this.getwidthItems(),
      maxWidth: this.getMaxWidthItems(),
      borderRadius:this.borderRadiusContainer() +"px",
    };

    const originStyle = {
      lineHeight: "1.1",
      fontWeight: "bold",
      fontFamily: this.props.item.fontFamilySecondary,
      color: this.props.item.colorAccent,
      fontSize: this.getFontAdjustment(this.props.item.fontSizeOrigin) + "px",
      borderRadius:this.borderRadiusContainer() +"px",
      zindex:"100",
    };
    const titleStyle = {
      lineHeight: "1.1",
      fontWeight: "bold",
      fontFamily: this.props.item.fontFamily,
      color: this.props.item.colorPrimary,
      fontSize: this.getFontAdjustment(this.props.item.fontSizeTitle) + "px",
      width:"85%",
    };

    const titleNIStyle = {
      lineHeight: "1.1",
      fontWeight: "bold",
      fontFamily: this.props.item.fontFamily,
      color: this.props.item.colorPrimary,
      fontSize: this.getFontAdjustment(this.props.item.fontSizeTitleNi) + "px",
      width:"85%",
    };
    const titleSecStyle = {
      lineHeight: "1.1",
      fontWeight: "bold",
      fontFamily: this.props.item.fontFamily,
      color: this.props.item.colorPrimary,
      fontSize: this.getFontAdjustment(this.props.item.fontSizeTitleSec) + "px",
      width:"85%",
    };
    const titleSecNIStyle = {
      lineHeight: "1.1",
      fontWeight: "bold",
      fontFamily: this.props.item.fontFamily,
      color: this.props.item.colorPrimary,
      fontSize: this.getFontAdjustment(this.props.item.fontSizeTitleSecNi) + "px",
      width:"85%",
    };

    const descriptionStyle = {
      lineHeight: "1.1",
      fontFamily: this.props.item.fontFamily,
      color: this.props.item.colorPrimary,
      fontSize: this.getFontAdjustment(this.props.item.fontSizeDescription) + "px",
    };

    const descriptionNIStyle = {
      lineHeight: "1.1",
      fontFamily: this.props.item.fontFamily,
      color: this.props.item.colorPrimary,
      fontSize: this.getFontAdjustment(this.props.item.fontSizeDescriptionNi) + "px",
    };

    const descriptionSecStyle = {
      lineHeight: "1.1",
      fontFamily: this.props.item.fontFamily,
      color: this.props.item.colorPrimary,
      fontSize: this.getFontAdjustment(this.props.item.fontSizeDescriptionSec) + "px",
    };
    const descriptionSecNIStyle = {
      lineHeight: "1.1",
      fontFamily: this.props.item.fontFamily,
      color: this.props.item.colorPrimary,
      fontSize: this.getFontAdjustment(this.props.item.fontSizeDescriptionSecNi) + "px",
    };

    const containerLabelStyle = {
      position: "relative",
      alignSelf: "flex-end",
      top: "-40px",
      right: "5px",
      display: "flex",
      width: "100%",
      justifyContent: "end",
      height: 0,
    };
    const containerLabelStyle_AGR = {
      position: "relative",
      alignSelf: "flex-end",
      top: "-40px",
      right: "100px",
      display: "flex",
      width: "100%",
      justifyContent: "end",
      height: 0,
    };
    const tagImageStyle = {
      backgroundColor: "#F6F6F6",
      borderRadius: "100%",
      objectFit: "cover",
      width: "60px",
      height: "60px",
      float: "left",
      margin: "0 3px",
    };
    const tagImageStyle_AGR = {
      backgroundColor: "#F6F6F6",
      borderRadius: "100%",
      objectFit: "cover",
      width: "60px",
      height: "60px",
      float: "left",
      margin: "0 3px",
      borderStyle:"solid",
      borderColor:"#9b223e",
      zIndex: "100",
    };  
    const bodyStyle = {
      flexDirection: "column",
      display: "flex",
      position: "relative",
      width: "100%",
      height:"100%",
    };
    const bodyStyleNi = {
      flexDirection: "column",
      display: "flex",
      position: "relative",
      width: "100%",
      height: "100%",
    };

    const overflowStyle = {
      //overflow: "hidden",
      display: "flex",
      flexDirection: "column",
      height: "100%",
      padding: "0 8px",
    };
    const allergyContStyle_AGR = {
      display: "block",
      padding: "0% 5px",
      display: "flex",
     // flexWrap: "wrap",
      paddingLeft: 0,
    };
    const allergyContStyle = {
      //display: "block",
      padding: "0 5px",
      display: "flex",
      //flexWrap: "wrap",
      paddingLeft: 0,
    };

    const allergyIconStyle = {
      fill: this.props.item.colorAccent,
      textAlign: "center",
      width: "30px",
      margin: "3px 0",
    };

    const caloriesStyle = {
      marginBottom: "0",
      lineHeight: "1.1",
      color: this.props.item.colorAccent,
      fontSize: this.getFontAdjustment(this.props.item.fontSizeCalories) + "px",
      fontFamily: this.props.item.fontFamilySecondary,
    };

    const containerPricesStyle = {
      position: "absolute",
      bottom: "110px",
      right: "-20px",
      backgroundColor: this.props.item.colorPrice,
      maxWidth: "170px",
      border: "1px solid #fff",
      textAlign: "center",
      padding: "5px",
    };
    const containerPricesStyle_agr = {
      position: "absolute",
      bottom: this.getHexagonStyleBottomMargin() +"%" ,
      right: this.containerPricesStyleRight(), //"35px",
     /*top: "35px",*/
      /*backgroundColor: this.state.color_priceBg,*/
      backgroundColor: "lightgrey",
      //maxWidth: "100px",
      border: "1px solid #fff",
      textAlign: "center",
      padding: this.getHexagonStylePadding() +"px",
      //borderRadius:"42px",
     
     
    };
    const containerPricesStyle_agr2 = {
      position: "absolute",
      bottom: "30%" ,
      right: "35px",
     /*top: "35px",*/
      backgroundColor: "lightgrey",
      maxWidth: "100px",
      border: "1px solid #fff",
      textAlign: "center",
      padding: "25px",
      //borderRadius:"42px",
     
     
    };
    const pricesStyle_AGR = {
      marginBottom: 0,
      MarginTop: "10px",
     // padding: "5px",
      textAlign: "center",
       fontFamily: this.props.item.fontFamily_price,
      color: this.props.item.colorPriceTx,
      fontSize: this.getFontAdjustment(this.props.item.fontSize_price) + "px",
      fontWeight: "bold",
      position:"relative",

    };
    const pricesStyle_AGR_h6 = {
      marginBottom: 0,
      //MarginTop: "10px",
      //padding: "5px",
      textAlign: "center",
       fontFamily: this.props.item.fontFamily_price,
      color: this.props.item.colorPriceTx,
      fontSize: "1.1em",//this.state.fontSize_price + "px",
     // fontWeight: "bold",
     position:"relative",

    };

    const pricesStyle = {
      marginBottom: 0,
      padding: "5px",
      textAlign: "center",
      fontFamily: this.props.item.fontFamilyPrice,
      color: this.props.item.colorPriceTx,
      fontSize: this.props.item.fontSizePrice + "px",
    
    };
    const containerfoodItemStyle = {
      height: "100%",
      minHeight: "100%",
      display: "flex",
      flexDirection: "column",
     // margin: "15px 30px",
    };
    return (
      <>
        <div style={divStyle}>
          <div style={containerStyle}>
            {this.state.foodgroup[0] &&
              this.state.foodgroup
                .filter(function (value) {
                  return value.active !== false;
                })
                .slice(0, this.getmaxFoodItem())
                .map((fooditem, i) => {
                  return (
                    <div style={foodItemStyle} className="newshadow">
                      <div style={containerfoodItemStyle}>
                        {fooditem.config.isUsingImage === true && this.props.item.switchDiamondbackground == false &&
                          <ImageFoodGroup
                            ImageUploaded={this.props.item.headerImage}
                            foodItemTemplate={fooditem}
                            foodItemImage={fooditem.template.picto}
                            id_foodgroup={this.props.item.id}
                            color_accent={this.props.item.colorAccent}
                          />}
                        <div
                          style={
                            fooditem.config && fooditem.config.isUsingImage === false
                              ? bodyStyleNi
                              : bodyStyle
                          }
                        >  
                         <div style={VerticaldivStyle} className="vertical-line2">
                          </div>
                          <div style={overflowStyle}>
                           <div className="containerCardFg">
                              {!fooditem.tags
                                ? null
                                : fooditem.tags
                                  .filter(function (value) {
                                    return value.tag.kind == "ORIGIN";
                                  })
                                  .map(function (tag, i) {
                                    return (
                                      <h4
                                        style={originStyle}
                                        className="itemCardFg"
                                      >
                                        <FontAwesomeIcon
                                          icon={faMapMarker}
                                          className="mr-2"
                                        />
                                        {tag.tag.name}
                                      </h4>
                                    );
                                  })}

                              {!fooditem.name ? null : (
                                <h3
                                  className="itemCardFg"
                                  style={
                                    fooditem.config.isUsingImage === false
                                      ? titleNIStyle
                                      : titleStyle
                                  }
                                >
                                  {fooditem.name}
                                </h3>
                              )}
                              {!fooditem.name_secondary ? null : (
                                <h3
                                  className="itemCardFg"
                                  style={
                                    fooditem.config.isUsingImage === false
                                      ? titleSecNIStyle
                                      : titleSecStyle
                                  }
                                >
                                  {fooditem.name_secondary}
                                </h3>
                              )}
                              {!fooditem.description ? null : (
                                <h3
                                className="itemCardFg"
                                  style={
                                    fooditem.config.isUsingImage === false
                                      ? descriptionNIStyle
                                      : descriptionStyle
                                  }
                                >
                                  {fooditem.description}
                                </h3>
                              )}
                              {!fooditem.description_secondary ? null : (
                                <h3
                                  className="itemCardFg"
                                  style={
                                    fooditem.config.isUsingImage === false
                                      ? descriptionSecNIStyle
                                      : descriptionSecStyle
                                  }
                                >
                                  {fooditem.description_secondary}
                                </h3>
                              )}
                              {!fooditem.calories ? null : (
                                <h4
                                  className="itemCardFg"
                                  style={caloriesStyle}
                                >
                                  Calories: {fooditem.calories} kcal
                                </h4>
                              )}
                              {!fooditem.tags ? null : (
                                <div
                                  className="itemCardFg"
                                  style={this.props.item.switchDiamondbackground === false || (fooditem.description.length !== 0 && this.props.item.switchDiamondbackground === true) ? allergyContStyle : allergyContStyle_AGR}
                                >
                                  {fooditem.tags
                                    .filter(function (value) {
                                      return value.tag.kind == "ALLERGEN";
                                    })
                                    .map((item) => {
                                      return (
                                        <h3 style={allergyIconStyle}>
                                          {this.getIconSvg(item.tag.name)}
                                        </h3>
                                      );
                                    })}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div style={this.props.item.switchDiamondbackground === false ? containerLabelStyle : containerLabelStyle_AGR}>
                        {!fooditem.tags
                          ? null
                          : fooditem.tags
                            .slice(0, 3)
                            .filter(function (value) {
                              return value.tag.kind == "LABEL";
                            })
                            .map(function (tag, i) {
                              return (
                                <Image
                                  className="newshadow"
                                  src={tag.tag.image}
                                  style={tagImageStyle_AGR}
                                />
                              );
                            })}
                      </div>
                      {//this.state.switchDiamondbackground === true ? false : (
                        //<hr width="1" size="auto" style="0 auto" />
                     // )
                    }
                      {fooditem.prices == null ? null : (
                        fooditem.prices[0].value === 0 || fooditem.prices[0].value === null ? null : (
                          <div
                          style={this.props.item.switchDiamondbackground === false ? containerPricesStyle : containerPricesStyle_agr}
                          className={this.props.item.switchDiamondbackground === false ? "newshadow" : "octa"

                          }  >
                           <h3 style={this.props.item.switchDiamondbackground === false ? pricesStyle : pricesStyle_AGR}>
                              {this.GetPriceAdjustment(fooditem.prices[0].value)}
                              </h3>
                              <h6 style={this.props.item.switchDiamondbackground === false ? pricesStyle : pricesStyle_AGR_h6}>
                             {this.props.item.switchDiamondbackground === true && fooditem.prices[0].currency === "Pts" ? fooditem.prices[0].currency ="points":""
                             }
                              {fooditem.prices[0].currency === " " ||
                                fooditem.prices[0].currency === "" ||
                                fooditem.prices[0].currency === "EUR" ||
                                fooditem.prices[0].currency === "Eur" ||
                                fooditem.prices[0].currency === "eur" ||
                                fooditem.prices[0].currency === "Euro"
                                ? "€"
                                : this.capitalize(
                                  fooditem.prices[0].currency.replace("PTS", "Points")
                                )}
                            </h6>
                          </div>
                        )
                      )}
                    </div>
                  );
                })}
          </div>
        </div>
      </>
    );
  }
}

export default FoodGroup_Land;
