import React from "react";

class VerticalLine extends React.Component {
  render() {
    if (this.props.item.height === undefined)
      this.props.item.height= "100" ;
    
    const divStyle = {
      margin: "0",
      border: "2px solid",
      borderColor: this.props.item.color,
      width: "0",
      height: this.props.item.height +"%",
    };

    return (
      <>
        <div className="container_component">
          <div style={divStyle}></div>
        </div>
      </>
    );
  }
}

export default VerticalLine;
